import styled from "styled-components";
import Banner from "../business/placeholderpictures/pic1.png";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.contracts.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const history = useHistory();
  const params = useParams();
  const [termschecked, setTermsChecked] = useState(false);
  const [signed, setSigned] = useState(false);

  const contract_terms = useQuery("contract_terms", async () => {
    return await axios
      .get(
        `${process.env.REACT_APP_API}/api/v1/consumer/contracts?string=${params.string}`
      )
      .then((res) => res.data);
  });

  console.log(contract_terms);

  const logo = useQuery("logo", async () => {
    return await axios
      .get(
        `${process.env.REACT_APP_API}/api/v1/consumer/contracts/logo?string=${params.string}`
      )
      .then((res) => res.data);
  });

  const signcontract = useMutation(
    async () => {
      return await axios
        .post(
          `${process.env.REACT_APP_API}/api/v1/consumer/contracts?string=${params.string}`
        )
        .then((res) => res.data);
    },
    {
      onSuccess: (data) => {
        setSigned(true);
      },
    }
  );
  const Logo = () => {
    return (
      <div style={{ margin: "auto" }}>
        <Image src={`data:image/png;base64,${logo.data}`} alt="logo" />
      </div>
    );
  };

  if (contract_terms.isError) {
    return (
      <div className="flex flex-col">
        <Content>
          <h1>
            {tr("Invalid contract key please contact the business directly")}
          </h1>
        </Content>
      </div>
    );
  } else if (contract_terms.isLoading) {
    return (
      <div className="flex flex-col">
        <Content>
          <h1>{tr("Loading...")}</h1>
        </Content>
      </div>
    );
  } else if (signed) {
    return (
      <div className="flex flex-col">
        <Content>
          <h1>
            {tr("The contract has been approved you may close this page")}
          </h1>
        </Content>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col">
        <Content style={{ marginBottom: "20px" }}>
          <Logo />
          <div
            style={{
              margin: "20px",
              width: "100%",
              alignItems: "center",

            }}
          >
            <h1
              style={{
  
                width: "100%",
                marginBottom: "20px",
              }}
            >
              {" "}
              {tr("Please read and agree to the Terms and Conditions below")}:
            </h1>
            {contract_terms?.data.description ? (
              <>
                <h3>{tr("Description")}:</h3>
                <div>
                  {contract_terms?.data.description.split(/\n/g).map((line, index) => (
                    <p key={index}>
                      {line === "" ? <br /> : line} {/* Keeps extra empty lines */}
                    </p>
                  ))}
                </div>
              </>
            ) : null}
          </div>
          <div
            style={{
              height: "20%",
              width: "100%",
              margin: "auto",
              borderRadius: "15px",
              padding: "20px",
              background: "#D3D3D380",
            }}
          >
            <h3>{tr("Terms and Conditions")}:</h3>
            {/* <p>{contract_terms?.data.terms_and_conditions}</p> */}

            {contract_terms?.data.terms_and_conditions ? (
              <div>
                {contract_terms?.data.terms_and_conditions.split(/\n/g).map((line, index) => (
                  <p key={index}>
                    {line === "" ? <br /> : line} {/* Keeps extra empty lines */}
                  </p>
                ))}
              </div>
            ) : null}
            <br/>
            <div className="flex items-center space-x-2">
              <input 
                className="w-5 h-5"
                type="checkbox"
                id="terms"
                name="terms"
                checked={termschecked}
                onChange={() => {
                  setTermsChecked(!termschecked);
                }}
              />
              <p className=" font-bold">
                {tr("I have read and agree to the above Terms and Conditions")}
              </p>
            </div>
          </div>
          <button
            style={{
              marginTop: "20px",
              float: "right",
              color: "#fff",
              padding: "8px",
              background: "#ff4f23",
              borderRadius: "5px",
            }}
            onClick={() => {
              if (termschecked) {
                signcontract.mutate();
              } else {
                alert("Please read and agree to the Terms and Conditions");
              }
            }}
          >
            {tr("Confirm")}
          </button>
        </Content>
      </div>
    );
  }
}

const Image = styled.img`
  width: 300px;
  margin: auto;
`;

const Content = styled.div`
  width: 100%;
  align-self: center;
  max-width: 900px;
`;
