import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { BsClipboard, BsCheck, BsX } from "react-icons/bs";
import { useQuery } from "react-query";
import axios from "axios";
import { SubtleA } from "components/button";
import { RiDownloadFill } from "react-icons/ri";
import SafeArea from "components/safearea";
import ViewPDF from "./viewPDF";
import { useTranslation } from 'react-i18next';
import Spinner from "components/spinner";
import { MdAttachFile } from "react-icons/md";
import { useModal } from "contexts/modalContext";

export default function Estimate() {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.estimate.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const { setShow } = useModal();
  
  const { id } = useParams();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [files, setFiles] = useState([]);
  const [filesLoading, setFilesLoading] = useState(false);

  const fetchData = useQuery(
    "estimate",
    async () => {
      return await axios
        .get(
          `
          ${process.env.REACT_APP_API}/api/v1/consumer/estimates/${id}
          `
        )
        .then((res) => res.data);
    },
    {
      onSuccess: async (data) => {
        if (data?.options?.length === 1) {
          setSelectedOptions([data.options[0].id]);
        }

        setFilesLoading(true);
        // Perform second API call
        if (data?.files?.length > 0) {
          const type = data.fk_request_parent_id ? "request" : "project";
          const typeId = data.fk_request_parent_id || data.fk_project_id;

          const objects = await Promise.all(
            data.files.map(async (file) => {
              try {
                const response = await axios.get(
                  `${process.env.REACT_APP_API}/api/v1/company/files/${type}/${typeId}/${file}/objects`
                );

                return { name: file, thumbnail: response.data.thumbnail, file: response.data.object }; // Save file name and URL
              } catch (error) {
                console.error(`Failed to fetch file ${file}:`, error);
                return null;
              }
            })
          );

          setFiles(objects.filter(Boolean));
        }
        setFilesLoading(false);
      },
      refetchOnWindowFocus: false,
    }
  );

  if (fetchData.isLoading) {
    return (
      <div className="flex flex-col justify-center items-center mt-4">
        {tr("Loading")}
        <Spinner size={30} className="text-[#ff4f00]" color="#ff4f00" />
      </div>
    )
  };

  if (fetchData.isSuccess) {
    let data = fetchData.data;

    const responded = data.status.id !== 3;
    const completed = data.status.id === 5;

    const openAttachmentsModal = () => {
      const openBase64File = (base64, mimeType) => {
        // Decode base64 string
        const byteCharacters = atob(base64.split(",")[1]);
        const byteNumbers = new Array(byteCharacters.length).fill().map((_, i) => byteCharacters.charCodeAt(i));
        const byteArray = new Uint8Array(byteNumbers);
    
        // Create a Blob from the byte array and generate an Object URL
        const blob = new Blob([byteArray], { type: mimeType });
        const url = URL.createObjectURL(blob);
        return url;
      };
    
      setShow({
        label: t("Attachments"),
        component: (
          <div className="flex flex-wrap justify-center gap-2 mt-2 max-w-[700px] min-w-[300px]">
            {files.length === 0 ? ( 
              <div className="w-full flex justify-center items-center">
                {tr("No attachments")}
              </div>
            ) : (
              files.map((file) => {
                const fileUrl = openBase64File(
                  file.file,
                  file.file.startsWith("data:application/pdf")
                    ? "application/pdf"
                    : "image/jpeg"
                );
                return (
                  <div key={file.name}>
                    <a
                      href={fileUrl}
                      target="_blank"
                      rel="noreferrer"
                      className="no-underline"
                    >
                      <img
                        src={file.thumbnail}
                        alt={file.name}
                        className="border w-[150px] h-[150px] object-contain max-w-[150px] rounded-[8px] mb-1 hover:shadow-lg"
                      />
                      <p className="mx-auto text-center max-w-[140px] truncate">
                        {file.name}
                      </p>
                    </a>
                  </div>
                );
              })
            )}
          </div>
        ),
      });
    };
    


    const sumTotal = data.options
      .filter(
        (option) => selectedOptions.includes(option.id) || option.selected
      )
      .reduce((prev, option) => prev + option.total, 0);

    const address = data.request || data.project;

    return (
      <>
        {/* Header */}
        <div className="border-b-2 mb-4 border-gray-200 w-full bg-white p-4 flex justify-between items-center space-x-2">
          <h3>
            {tr("Estimate")} #{data.id}
          </h3>
          <h1>{data.description}</h1>
          <div>
            <span className="font-bold">{tr("To")}: </span>
            <span>{data.customer.name}</span>
          </div>
          {address.address && (
            <div>
              <span className="font-bold">{tr("Address")}: </span>
              <span>
                {`${address.address.address}, ${address.address.city}, ${address.address.state} ${address.address.zipcode}`}
              </span>
            </div>
          )}
          <div>
            {tr("Status")}:{" "}
            <span style={{ color: data.status.id === 5 ? "green" : "red" }}>
              {tr(data.status.label)}
            </span>
          </div>

          <SubtleA
            primary
            target="_blank"
            style={{ alignSelf: "flex-start", padding: "0" }}
            to={{ pathname: data.pdf }}
          >
            <RiDownloadFill />
            {tr("Download PDF")}
          </SubtleA>
          <button 
            onClick={openAttachmentsModal} 
            disabled={filesLoading}
            className={`
              relative flex items-center gap-1  border border-gray-300 rounded-md p-2
              hover:bg-gray-100
              ${filesLoading ? "cursor-not-allowed bg-gray-100" : "cursor-pointer bg-white"}
            `}
          >
            <div className={`text-[13.6px] font-bold ${filesLoading ? "text-gray-400" : "text-gray-600"}`}>
              {tr("Attachments")}
            </div>
            <MdAttachFile />
            <div className="absolute top-0 right-0 -mt-2 -mr-2 w-5 h-5 bg-white border rounded-full">
              {filesLoading ? 
                <Spinner size={20} className="text-[#ff4f00]" color="#ff4f00" />
                : <div className="text-[#1e90ff] -mt-[3px] font-bold">{files.length}</div>
              }
            </div>
          </button>
        </div>
        <SafeArea>
          {responded ? (
            <div>
              <div className="flex flex-col items-center">
                <div className="relative">
                  <BsClipboard size={80} className=" my-4" />
                  <div
                    className={`
                      absolute bottom-0 right-[5px] transform translate-x-1/2 rounded-full p-0.5 text-white text-xl
                      ${completed ? "bg-green-600" : "bg-red-600"}
                    `}
                  >
                    {completed ? <BsCheck /> : <BsX />}
                  </div>
                </div>

                <span>
                  {tr("Estimate")}{" "}
                  <span style={{ color: completed ? "green" : "red" }}>
                    {completed ? tr("accepted") : tr("declined")}
                  </span>
                </span>
                <p>
                  {data.name}{" "}
                  {tr(
                    "has received the estimate confirmation! For questions, please contact us at"
                  )}{" "}
                  <a href={`mailto:${data.email}`}>{data.email}</a>
                </p>
              </div>
            </div>
          ) : (
            <ViewPDF estimateId={data.id} tempId={id} options={data.options} />
          )}
        </SafeArea>
      </>
    );
  }
}
