import React, { useState, useEffect } from "react";
import axios from "axios";
import { useQuery, useMutation } from "react-query";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { PDFDocument, rgb, degrees } from "pdf-lib";
import { Button } from "components/button";
import { useTranslation } from 'react-i18next';
import { dollarFormatter } from "utils";
import { FaInfoCircle } from "react-icons/fa";
import DeclineModal from "./declineModal";
import { useModal } from "contexts/modalContext";

const ViewPDF = ({ estimateId, tempId, options }) => {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.estimate.viewPDF";
  const tr = (key) => t(`${tBase}.${key}`);
  const { setShow } = useModal();

  const [updatedPdf, setUpdatedPdf] = useState(null);
  const [initials, setInitials] = useState("");
  const [signature, setSignature] = useState("");
  const [pdfAnnotations, setPdfAnnotations] = useState([]);
  const [yPosition, setYPosition] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [hasSigned, setHasSigned] = useState(false);
  const [additionalTerms, setAdditionalTerms] = useState([]);
  const [counters, setCounters] = useState({
    initial: 0,
    signature: 0,
    date: 0,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [actualTotal, setActualTotal] = useState(0);
  const [hasTaxes, setHasTaxes] = useState(false);
  const [hoverInfo, setHoverInfo] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY >= 140) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scroll = () => {
    window.scrollTo(0, yPosition); 
  };

  const { data, isLoading, error } = useQuery("pdf", async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/api/v1/consumer/estimates/pdf/${estimateId}`,
      { responseType: "text" }
    );
    return response.data; // Returns the S3 URL
  });

  const submitEstimate = useMutation(
    async (formData) => {
      return await axios.post(
        `${process.env.REACT_APP_API}/api/v1/consumer/estimates/pdf/${tempId}/estimate-response`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    {
      onSuccess: () => {
        setIsSubmitting(false);
        window.location.reload();
      },
      onError: (error) => {
        setIsSubmitting(false);
        console.error("Submission error:", error);
      },
    }
  );

  const pdfXY = data?.locations?.pdf_xy;

  const drawRegions = async (pages, pageHeight, reset) => {
    const yellow = rgb(1, 1, 0); // highlight color
  
    Object.keys(pdfXY || {}).forEach((key) => {
      const regions = pdfXY[key];
      if (key === "tnc") return; // skip terms and conditions

      // if only one option automatically select it
      if (key === "option" && regions.length === 1) {
        const region = regions[0];
        const page = pages[region.page - 1];
        page.drawText("X", {
          x: region.x + 4,
          y: pageHeight - region.y - 11.5,
          size: 10,
          color: rgb(0, 0, 0),
        });
        setPdfAnnotations((prevAnnotations) => {
          const newAnnotations = [...prevAnnotations];
  
          if (!newAnnotations[region.page - 1]) {
              newAnnotations[region.page - 1] = { content: [] };
          }
  
          newAnnotations[region.page - 1].content.push({
              text: "X",
              x: region.x + 4,
              y: region.y + 11.5,
              page: region.page,
              type: "option"
          });
  
          return newAnnotations;
        });
        setSelectedOptions((prev) => {
          const newOptions = new Set(prev);
          newOptions.add(region.id);
          return [...newOptions]; // Convert back to array
        });
        
        setActualTotal((prev) => {      
          const option = options.find((option) => option.id === region.id);
          if (!selectedOptions.includes(option.id)) {
            return prev + option.total;
          }
          return prev;
        });
        setHasTaxes((prev) => {
          const option = options.find((option) => option.id === region.id);
          const bool = option?.taxes?.length > 0 ? true : false;
          return bool;
        });
      }

      if (regions) {
        regions.forEach((region) => {
          // Skip if only one option and already selected
          if (key === "option" && regions.length === 1) return;

          // date gets added when signing so skip
          if (key === "date") return;

          const pageIndex = region.page - 1;
          const annotated = pdfAnnotations[pageIndex]?.content.some((anno) => {
            // Compare page and coordinates
            return (
              anno.page === region.page &&
              Math.abs(anno.x - region.x) < 15 &&
              Math.abs(anno.y - region.y) < 15
            );
          });
  
          // If not annotated, draw highlight
          if (!annotated || reset) {
            const width = region.width || 15;
            const height = region.height || 15;
            const page = pages[pageIndex];
            page.drawRectangle({
              x: region.x,
              y: pageHeight - region.y - height,
              width,
              height,
              color: yellow,
              opacity: 0.4,
            });
          }
        });
      }
    });
  };

  const drawRegionsOnLoad = async (reset) => {
    const response = await axios.get(data.pdf, { responseType: "arraybuffer" });
    const pdfDoc = await PDFDocument.load(response.data);
    const pages = pdfDoc.getPages();  
    const pageHeight = pages[0].getHeight();

    drawRegions(pages, pageHeight, reset);
    const annotations = pages.map((page) => ({ content: [] }));
    setPdfAnnotations(annotations);
  
    // Save the updated PDF with regions
    const pdfBytes = await pdfDoc.save();
    const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
    setUpdatedPdf(URL.createObjectURL(pdfBlob));
  };

  useEffect(() => {
    if (data && pdfXY) {
      drawRegionsOnLoad();
    }
  }, [pdfXY]);

  const handlePdfClick = async (event) => {
    if (!data?.pdf) return;

    setYPosition(window.scrollY);
    const response = await axios.get(data.pdf, { responseType: "arraybuffer" });
    const pdfDoc = await PDFDocument.load(response.data);

    const pages = pdfDoc.getPages();
    const pageWidth = pages[0].getWidth(); 
    const pageHeight = pages[0].getHeight();
  
    // Map click position to PDF coordinates
    const rect = event.target.getBoundingClientRect();
    
    const containerWidth = rect.width;
    const containerHeight = rect.height;
  
    const clickX = ((event.clientX - rect.left) / containerWidth) * pageWidth;
    const clickY = ((event.clientY - rect.top) / containerHeight) * pageHeight;

    const checkRegion = (regions) => {
      return regions.find((r) => {
        const w = r.width || 15; 
        const h = r.height || 15;
        return (
          clickX >= r.x &&
          clickX <= r.x + w &&
          clickY >= r.y &&
          clickY <= r.y + h
        );
      });
    };
    
    const clickedOption = pdfXY.option ? checkRegion(pdfXY.option) : null;
    const clickedSignature = pdfXY.signature ? checkRegion(pdfXY.signature) : null;
    const clickedInitial = pdfXY.initial ? checkRegion(pdfXY.initial) : null;
    const clickedYes = pdfXY.yes ? checkRegion(pdfXY.yes) : null;
    const clickedNo = pdfXY.no ? checkRegion(pdfXY.no) : null;
  
    let textToDraw = "";
    let contentX, contentY, targetPage, type;
  
    if (clickedOption) {
      textToDraw = "X"; 
      contentX = clickedOption.x + 4; 
      contentY = clickedOption.y + 11.5;
      targetPage = clickedOption.page;
      type = "option";
      setSelectedOptions((prev) => [...prev, clickedOption.id]);
      setActualTotal((prev) => {      
        const option = options.find((option) => option.id === clickedOption.id);
        if (!selectedOptions.includes(option.id)) {
          return prev + option.total;
        }
        return prev;
      });
      setHasTaxes((prev) => {
        if (prev === true) {
          return true;
        } {
          const option = options.find((option) => option.id === clickedOption.id);
          const bool = option?.taxes?.length > 0 ? true : false;
          return bool;
        }
      })
    } else if (clickedYes || clickedNo) {
      const clickedYN = clickedYes || clickedNo;
      textToDraw = "X"; 
      contentX = clickedYN.x + 1.6; 
      contentY = clickedYN.y + 8.5;
      targetPage = clickedYN.page;
      type = clickedYes ? "yes" : "no";
    } else if (clickedSignature && signature !== "") {
      textToDraw = signature;
      contentX = clickedSignature.x;
      contentY = clickedSignature.y + 12.5;
      targetPage = clickedSignature.page;
      setCounters((prev) => ({
        ...prev,
        signature: prev.signature + 1,
      }));
      type = "signature";
      if (signature) setHasSigned(true);
    } else if (clickedInitial && initials !== "") {
      textToDraw = initials;
      contentX = clickedInitial.x;
      contentY = clickedInitial.y + 13.5;
      targetPage = clickedInitial.page;
      if (initials !== "") {
        setCounters((prev) => ({
          ...prev,
          initial: prev.initial + 1,
        }));
      }
      type = "initial";
    } else {
      return;
    }

    // add to additional terms for yes/no | initial | yes/no + initial
    if (clickedYes || clickedNo || clickedInitial || (clickedSignature && pdfXY.signature[1].y === clickedSignature.y)) {
      const clickedYNI = clickedYes || clickedNo || clickedInitial;
      // add or update additional terms
      setAdditionalTerms((prev) => {
        // signature exclusive, pairing to terms and conditions
        if (clickedSignature && pdfXY.signature[1].y === clickedSignature.y) {
          const tnc = pdfXY.tnc[0];
          return [
            ...prev,
            {
              id: tnc.id,
              label: tnc.label,
              text: tnc.text || "",
              accepted: true,
              type: tnc.type,
              signature: signature,
            }
          ]
        } else { 
          const index = prev.findIndex((term) => term.id === clickedYNI.id);
          if (index !== -1) { // update yes to no or vice versa
            const updatedTerms = [...prev];
            updatedTerms[index] = {
              ...updatedTerms[index],
              accepted: type === "yes" ? true : type === "no" ? false : updatedTerms[index].accepted,
            };
            return updatedTerms;
          } else { // regular add
            return [
              ...prev,
              {
                id: clickedYNI.id,
                label: clickedYNI.label,
                text: clickedYNI.text || "",
                accepted: type === "yes" ? true : type === "no" ? false : null,
                initial: initials || null, 
                type: clickedYNI.type,
              },
            ];
          }
        }
      });
    }

    const updatedAnnotations = [...pdfAnnotations];

    // skip if clicking yes or no on same y position
    if (type === "yes" || type === "no") {
      const otherType = type === "yes" ? "no" : "yes";
    
      // find and remove both "yes" and "no" annotations on the same y position
      updatedAnnotations[targetPage - 1].content = updatedAnnotations[targetPage - 1].content.filter((anno) => {
        return !(anno.type === type || anno.type === otherType) || Math.abs(anno.y - contentY) >= 15;
      });
    }

    // draws date when signing
    if (type === "signature" && signature !== "") {
      const matchingDateRegion = pdfXY.date.find((dateRegion) => {
        return (
          Math.abs(dateRegion.y - contentY) < 15 &&
          dateRegion.page === targetPage
        );
      });
  
      if (matchingDateRegion) {
        const existingDateAnnotation = updatedAnnotations[targetPage - 1].content.find((anno) => {
          return (
            anno.type === "date" &&
            Math.abs(anno.y - contentY) < 15
          );
        });
    
        if (!existingDateAnnotation) {
          updatedAnnotations[targetPage - 1].content.push({
            text: new Date().toLocaleDateString(),
            x: matchingDateRegion.x,
            y: matchingDateRegion.y + 12.5,
            page: targetPage,
            type: "date",
          });
        }
      }
    }

    if (textToDraw.trim() !== "") { // prevents adding empty annotations from signature / initials
      updatedAnnotations[targetPage - 1].content.push({
        text: textToDraw,
        x: contentX,
        y: contentY,
        page: targetPage,
        type,
      });
    }
    setPdfAnnotations(updatedAnnotations);

    pages.forEach((page, pageIndex) => {
      const pageAnnotations = updatedAnnotations[pageIndex]?.content || [];
        // add annotations
        pageAnnotations.forEach((annotation) => {
          page.drawText(annotation.text, {
            x: annotation.x,
            y: page.getHeight() - annotation.y,
            size: 10,
            color: rgb(0, 0, 0),
          });
        });
    });

    drawRegions(pages, pageHeight);

    // Save the updated PDF
    const pdfBytes = await pdfDoc.save();
    const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
    setUpdatedPdf(URL.createObjectURL(pdfBlob));
  };

  const handleSubmit = async (acceptOrDeclineString, declineNote) => {
    setIsSubmitting(true);
    let pdfBlob;
    const response = await axios.get(data.pdf, { responseType: "arraybuffer" });
    const pdfDoc = await PDFDocument.load(response.data);
    const pages = pdfDoc.getPages();


    if (acceptOrDeclineString === "decline") {
      // drawText Declined big red letters on all pages to send in email

      pages.forEach((page) => {
        const { width, height } = page.getSize(); // Get page dimensions
        page.drawText("Declined", {
          x: width / 2.75, 
          y: height / 1.5, 
          size: 60,
          color: rgb(0, 0, 0), 
          rotate: degrees(-45),
        });
      });
      
      const pdfBytes = await pdfDoc.save();
      pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
    } else { // accepted
      if (!updatedPdf || !signature || !hasSigned) {
        return;
      }

      pdfAnnotations.forEach((pageAnnotations, pageIndex) => {
        const page = pages[pageIndex];
        pageAnnotations.content.forEach((annotation) => {
          page.drawText(annotation.text, {
            x: annotation.x,
            y: page.getHeight() - annotation.y,
            size: 10,
            color: rgb(0, 0, 0),
          });
        });
      });
      const pdfBytes = await pdfDoc.save();
      pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
    }

    const formData = new FormData();
    formData.append("file", pdfBlob, `${estimateId}-signed.pdf`);
    formData.append("status", acceptOrDeclineString);
    formData.append("signature", signature);  
    formData.append("selectedOptions", JSON.stringify(selectedOptions));
    formData.append("fileName", `${estimateId}-signed.pdf`);
    formData.append("additionalTerms", JSON.stringify(additionalTerms));
    formData.append("declineNote", declineNote);

    submitEstimate.mutate(formData);
  };

  const disabled = (!signature || selectedOptions.length === 0 || !hasSigned) 
    || (pdfXY.yes && additionalTerms && pdfXY.yes.length > additionalTerms.length) 
    || (pdfXY.initial && pdfXY.initial.length > counters.initial)
    || (pdfXY.signature && pdfXY.signature.length > counters.signature);

  if (isLoading) return <p>{tr("Loading")}...</p>;
  if (error) return <p>{tr("Failed to load the PDF")}: {error.message}</p>;

  return (
    <div className="mb-5 relative w-full">
      {/* Input Fields for Initials and Signature */}
      <div
        className={`${
          isFixed ? "fixed top-0 left-0 w-full shadow-lg" : "relative"
        } lg:px-20 md:px-10 sm:px-5 min-w-[720px] flex items-center justify-between bg-white py-3 rounded-lg z-20`}
      >
        <div className="space-x-3">
          {pdfXY.initial && (
            <label>
              {tr("Initials")}:{" "}
              <input
                type="text"
                value={initials}
                className="h-8 w-10"
                maxLength={2}
                onChange={(e) => {
                  e.target.value = e.target.value.toUpperCase();
                  setInitials(e.target.value);
                }}
              />
            </label>
          )}
          <label>
            {tr("Signature")}:{" "}
            <input
              type="text"
              value={signature}
              onChange={(e) => setSignature(e.target.value)}
              className="h-8"
            />
          </label>
        </div>
        
        <div className="flex flex-col items-center space-x-3">
          <div>{tr("Selected Total")}</div>
          <div className="text-[#ff4f00] flex items-center space-x-2 relative">
            <div>{dollarFormatter(actualTotal)}</div>
            {hasTaxes && (
            <div 
              className="relative"
              onMouseOver={() => setHoverInfo(true)}
              onMouseLeave={() => setHoverInfo(false)}
            >
              <FaInfoCircle />
              {hoverInfo && (
                <div className="absolute left-1/2 top-full mt-1 -translate-x-1/2 z-50">
                  <div className="mt-1 bg-gray-700 py-1 px-2 rounded-md text-white text-xs whitespace-nowrap">
                    {tr("Taxes included")}
                  </div>
                  <span className="mt-1 absolute w-2 h-2 bg-gray-700 top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rotate-45" />
                </div>
              )}
            </div>
          )}
          </div>
        </div>

        <div className="flex items-center space-x-3">
          <Button
            isLoading={isSubmitting}
            onClick={() =>
              setShow({
                label: tr("Decline Confirmation"),
                component: (
                  <DeclineModal
                    handleSubmit={(note) => {
                      handleSubmit("decline", note);
                      setShow(null);
                    }}
                  />
                ),
              })
            }
            style={{ width: "30%" }}
          >
            {tr("Decline")}
          </Button>
          <Button
            primary
            isLoading={isSubmitting}
            onClick={() => handleSubmit("accept", null)}
            disabled={disabled}
          >
            {tr("Accept")}
          </Button>
          <Button
            isLoading={isSubmitting}
            onClick={() => {
              setInitials("");
              setSignature("");
              setPdfAnnotations([]);
              setSelectedOptions([]);
              setHasTaxes(false);
              setActualTotal(0);
              setAdditionalTerms([]);
              setCounters({
                initial: 0,
                signature: 0,
                yes: 0,
                date: 0,
              });
              setUpdatedPdf(null);
              setHasSigned(false);
              setYPosition(0);
              drawRegionsOnLoad(true);
            }}
          >
            {tr("Reset")}
          </Button>
        </div>
      </div>
      {/* PDF Viewer */}
      <div className="mx-auto" onClick={handlePdfClick}>
        {updatedPdf && (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <Viewer
              fileUrl={updatedPdf}
              onDocumentLoad={scroll}
              defaultScale={1.5}
            />
          </Worker>
        )}
      </div>
    </div>
  );
}

export default ViewPDF;
